
import {
  defineComponent,
  onMounted,
  ref,
  useAttrs,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  formatUtcDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import { MenuComponent } from "@/assets/ts/components";
import {
  RefundOverview,
  refundOverviewData,
} from "@/core/directive/interface/payment";
import { getRefundStatus } from "@/core/directive/function/paymentManagement";
import _ from "lodash";

export default defineComponent({
  name: "payment-management-refund-overview",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs();
    const loading = ref(true);

    const paymentEditModal = ref();
    const editId = ref("");

    const baseData = ref(Object.assign({}, _.cloneDeep(refundOverviewData)));

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
      }
    );

    watch(
      () => attrs.baseData,
      (newValue) => {
        const base = newValue as RefundOverview;
        baseData.value = base;
      },
      { immediate: true }
    );

    const getStatusClass = computed(() => {
      return (status) => {
        return getRefundStatus(status);
      };
    });

    onMounted(() => {
      if (!attrs.loading) {
        loading.value = false;
      }
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      t,
      loading,
      baseData,
      paymentEditModal,
      editId,
      getStatusClass,
      formatUtcDate,
      formatDateTime,
    };
  },
});
